import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      grow: {
        flexGrow: 1,
      },
      title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
      sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          alignItems: 'center',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuItem: {
        fontSize: '16px',
        fontFamily: 'Inter',
        fontColor: NovaColours.N020[theme.palette.mode],
        fontWeight: 400,
        alignItems: 'center',
        borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
        '&:first-child': {
          borderTop: 'none',
          '&:hover': {
            borderRadius: theme.spacing(0.625, 0.625, 0, 0),
          },
        },
        '&:last-child': {
          borderBottom: 'none',
          '&:hover': {
            borderRadius: theme.spacing(0, 0, 0.625, 0.625),
          },
        },
      },
      menuItemContainer: {
        '& .MuiList-root': {
          alignItems: 'center',
          paddingBottom: '0px !important',
          paddingTop: '0px !important',
          boxShadow: theme.shadows[5],
          border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
          borderRadius: '6px',
        },
      },
    }),
)
