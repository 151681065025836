/* eslint-disable import/extensions */
import { MenuItem } from '@mui/material'

import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useOAuth } from '@novafuturltd/core'
import { useMixPanel } from '../../contexts/mixpanel'

interface Props {
  className?: string | undefined
}

const TopbarUser: FC<Props> = ({className}) => {
  const { handleLogout: logOut } = useOAuth()
  const mxp = useMixPanel()
  const intl = useIntl()

  const handleLogOut = () => {
    logOut()
    mxp.track('Log Out')
  }

  return (
    <>
      {/* <MenuItem component={ButtonLink} as={`${baseUrl}/profile`} href="profile">
        {intl.formatMessage({ id: 'app.header.profile' })}
      </MenuItem> */}
      <MenuItem onClick={handleLogOut} className={className}>
        {intl.formatMessage({ id: 'app.header.logout' })}
      </MenuItem>
    </>
  )
}

export default TopbarUser
