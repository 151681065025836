import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
import { useIntl } from 'react-intl'
import ExperimentsIcon from '../../../../components/uielements/icons/ExperimentsIcon'

interface MenuIdentifypProps {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuClusterInfo = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}: MenuIdentifypProps) => {
  const { formatMessage } = useIntl()
  return (
    <div onClick={toggleDrawerOnMobile}>
      <MenuItem
        className={nested}
        component={ButtonLink}
        href="/responsible-gambling/cluster-info?range=monthly"
        selected={
          pathname.substring(0, 30) ===
          '/responsible-gambling/cluster-info?range=monthly'
        }
        // onClick={toggleDrawerOnMobile}
      >
        <ListItemIcon>
          <ExperimentsIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'responsible_gambling.menu.submenu.cluster.title',
          })}
        />
      </MenuItem>
    </div>
  )
}
