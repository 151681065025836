import { ListItemIcon, MenuItem, MenuList, ListItemText } from '@mui/material'
import { ButtonLink } from '../../../components/wrappers/Button'
import { useIntl } from 'react-intl'
import { SegmentIcon } from '@novafuturltd/shared/icons/segment.icon'
import { useMixPanel } from '../../../contexts/mixpanel'
import { TtoggleDrawerOnMobile } from '../Sidebar'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuCustomerSegmentsV3 = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}: Props) => {
  const { formatMessage } = useIntl()
  const { track } = useMixPanel()

  return (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      disablePadding={true}
    >
      <MenuItem
        key="overviewSegments"
        data-testid="customers_sidebar_menuitem_overview-segments"
        component={ButtonLink}
        href="/customers/segmentsV3"
        selected={'/customers/segmentsV3' === pathname}
        className={nested}
        onClick={() => {
          track('Sidebar Menu Item (Customer Segments V3)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <SegmentIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'sidebar.customers.segmentsV3' })}
        />
      </MenuItem>
    </MenuList>
  )
}
