import { CssBaseline, SvgIcon, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  AppContainer,
  AppFooter,
  AppHeader,
  Main,
  SideBar,
  useWindowSize,
} from '@novafuturltd/shared'
import { NovaColours } from '@novafuturltd/core'
import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { MadeWithLove } from '../../components/made.with.love'
import { version } from '../../config'
import AppMenuList from '../../containers/Sidebar/Sidebar'
import HeaderComponents from '../Topbar/Topbar'
import { useAppContext } from '@novafuturltd/core'

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    releaseNotes: {
      marginRight: theme.spacing(3),
      color: NovaColours.N030[theme.palette.mode],
    },
    madeWithLove: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

const Layout: FC<Props> = ({ children }) => {
  const {
    isFooterVisible,
    view,
    openDrawer,
    hoverDrawer,
    // tslint:disable-next-line: no-shadowed-variable
    toggleAll,
    // tslint:disable-next-line: no-shadowed-variable
    toggleHoverDrawer,
    // tslint:disable-next-line: no-shadowed-variable
    toggleOpenDrawer,
  } = useAppContext()
  const intl = useIntl()
  const { width, height } = useWindowSize()
  const classes = useStyles({})
  useEffect(() => {
    toggleAll(width, height)
  }, [width, height])

  useEffect(() => {
    if (view === 'MobileView' && width < 767) {
      toggleOpenDrawer(false)
    }
  }, [view])

  const showFooter = () => {
    if (isFooterVisible) {
      if (view === 'MobileView' && openDrawer) {
        return false
      }
      return true
    }
    return false
  }

  return (
    <>
      <AppContainer>
        <CssBaseline />
        <AppHeader>
          <HeaderComponents />
        </AppHeader>
        <SideBar
          isFooterVisible={showFooter()}
          hover={hoverDrawer}
          toggleHoverDrawer={toggleHoverDrawer}
          open={openDrawer}
        >
          <AppMenuList />
        </SideBar>
        <Main isFooterVisible={isFooterVisible} openDrawer={openDrawer}>
          {children}
        </Main>
      </AppContainer>
      {showFooter() && (
        <AppFooter>
          <div>
            <span className={classes.releaseNotes}>
              {intl.formatMessage({ id: 'app.footer.version' }, { version })}
            </span>
            {/* <a href="release-note">
                         {intl.formatMessage({ id: 'app.footer.link' })}
                       </a> */}
          </div>
          {view !== 'MobileView' && <MadeWithLove />}
          <div>
            <SvgIcon fontSize="large" viewBox="-2 -2 24 24">
              <path
                d="M12 2L3.33974 7V17L12 22L20.6603 17V7L12 2Z"
                fill="#00D874"
              />
            </SvgIcon>
          </div>
        </AppFooter>
      )}
    </>
  )
}

export default Layout
