import {
  Box,
  Chip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  ListItemIcon,
  Paper,
  Popper,
  useTheme,
  ListItemText,
} from '@mui/material'
import { NovaColours } from '@novafuturltd/core'
import { useActionStyles } from '@novafuturltd/shared'
import { useRef, useState } from 'react'
import { useAppContext } from '@novafuturltd/core'
import { useOrganizationSettings } from '../containers/OrganisationSettings/context'
import {
  currencies,
  useCurrencyPreferenceContext,
} from '../contexts/CurrencyPreferenceContext'

const CurrencyPreferenceSelect = () => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const classes = useActionStyles({})
  const theme = useTheme()

  const {
    setCurrencyPreference,
    exchangeRatesQuery,
    effectiveCurrency,
  } = useCurrencyPreferenceContext()

  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const handleCurrencyPreferenceChange = (
    currency: Parameters<typeof setCurrencyPreference>[0],
  ) => {
    setCurrencyPreference(currency)
    setOpen(false)
  }

  const { mode } = useAppContext()

  const {
    query: { data: modules },
  } = useOrganizationSettings()

  const currencyExchangeModule = modules?.data.data.find(
    module => module.name === 'currency_exchange',
  )
  const currencyExchangeSubModule = currencyExchangeModule?.sub_modules.find(
    subModule => subModule.name === 'exchange_rates',
  )
  const enabledCurrencies = currencies.filter(currency =>
    currencyExchangeSubModule?.params.availableCurrencies.includes(
      currency.code,
    ),
  )

  const exchangeRates = exchangeRatesQuery?.data?.data.data || []

  return (
    <>
      <Chip
        variant="outlined"
        ref={anchorRef}
        data-testid="currency-preference-select"
        onClick={handleToggle}
        label={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 700, marginRight: theme.spacing(0.5) }}>
              {effectiveCurrency.symbol}
            </p>
            <p style={{ fontWeight: 400 }}>{effectiveCurrency.code}</p>
          </Box>
        }
        sx={{
          color: NovaColours.N040[mode],
          border: `1px solid ${NovaColours.N055[mode]}`,
          height: theme.spacing(3),
          fontColor: NovaColours.N055[mode],
        }}
      />
      <Popper
        anchorEl={anchorRef.current}
        placement="bottom-end"
        className={classes.menuItemContainer}
        disablePortal
        open={open}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  sx={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  {enabledCurrencies.map(currency => (
                    <MenuItem
                      disabled={
                        effectiveCurrency.code === currency.code ||
                        !exchangeRates.length ||
                        !exchangeRates.find(
                          rate =>
                            rate.currency.toLowerCase() ===
                            currency.code.toLowerCase(),
                        )
                      }
                      className={classes.menuItem}
                      key={currency.code}
                      sx={{ paddingLeft: theme.spacing(0.5) }}
                      onClick={() =>
                        handleCurrencyPreferenceChange(currency.code)
                      }
                    >
                      <Box sx={{ display: 'flex' }}>
                        <ListItemIcon
                          sx={{
                            margin: 'auto',
                            minWidth: 'auto !important',
                            paddingRight: '4px',
                          }}
                        >
                          {currency.flag}
                        </ListItemIcon>
                        <ListItemText>
                          {`${currency.symbol} ${currency.code}`}
                        </ListItemText>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default CurrencyPreferenceSelect
