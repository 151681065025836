import { SvgIcon, SvgIconProps } from '@mui/material'

export const SegmentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.1027 4.72744C13.2107 4.67244 14.3477 4.82444 15.4557 5.21544C19.8627 6.76844 22.1307 11.4744 20.5217 15.7284C18.9127 19.9814 14.0347 22.1704 9.62773 20.6164C5.22073 19.0644 2.95273 14.3574 4.56173 10.1044C4.61073 9.97544 4.67173 9.85444 4.72673 9.72944L13.8267 14.1634L12.1027 4.72744Z" />
      <path d="M8.65382 5.98211V3.24512H7.28533V5.98211H4.54834V7.3506H7.28533V10.0876H8.65382V7.3506H11.3908V5.98211H8.65382Z" />
    </SvgIcon>
  )
}
