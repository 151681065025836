/* eslint-disable import/prefer-default-export */
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'

import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const Reports: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  const [dateToday] = useState(new Date().toISOString())

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="recommendations"
        component={ButtonLink}
        href={`/recommendations-engine/reports?start=${dateToday.substring(
          0,
          10,
        )}T00:00:00&stop=${dateToday.substring(0, 19)}`}
        selected={pathname.includes('/recommendations-engine/reports')}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Recc-engine: Reports)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.recommendations.engine.reports',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
