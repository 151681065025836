import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DesktopTemplateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM14.5 9H4V12.5H14.5V9ZM4 14.5H14.5V18H4V14.5ZM16.5 18H20V9H16.5V18Z"
       />
    </SvgIcon>
  )
}
