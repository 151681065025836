import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuTemplates: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="templates"
        data-test="templates_sidebar_menuitem"
        component={ButtonLink}
        href="/campaign-mgmt/templates/0"
        selected={pathname.substring(0, 24) === '/campaign-mgmt/templates'}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Campaign-mgmt: Templates)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon data-test="campaign_template_sidemenu_icon">
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.campaign-mgmt.templates',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
