import {
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import SimulateIcon from '../../../components/uielements/icons/SimulateSidebarIcon'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { SimulateCoupons } from './simulate.coupons'
import { SimulateReports } from './simulate.reports'
import { useStyles } from '../Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'
// import { SimulateReports } from './simulate.reports'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  toggleOpen: (open: boolean) => void
}

const SimulateSubMenu: FC<Props> = ({
  permissions,
  openDrawer,
  hoverDrawer,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const { pathname } = useRouter()
  const [open, setOpen] = React.useState(pathname.includes('simulate/'))
  const handleClick = () => {
    setOpen(!open)
  }

  return permissionValidation(permissions, [
    'simulate_bets',
    'simulate_reports',
  ]) ? (
    <MenuList aria-labelledby="nested-list-subheader" className={classes.root}>
      <MenuItem
        key="simulate"
        data-test="simulate_sidebar_submenu"
        onClick={handleClick}
        className={classes.menuItem}
        selected={
          !openDrawer && !hoverDrawer && pathname.startsWith('/simulate')
        }
      >
        <ListItemIcon>
          <SimulateIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.simulate.index' })}
          hidden={!openDrawer && !hoverDrawer}
        />
        {open ? (
          <ExpandLess
            onClick={() => setOpen(false)}
            className={classes.expandIcon}
          />
        ) : (
          <ExpandMore
            onClick={() => setOpen(true)}
            className={classes.expandIcon}
          />
        )}
      </MenuItem>
      <Collapse in={open} timeout="auto" hidden={!openDrawer && !hoverDrawer}>
        <MenuList disablePadding={true}>
          {permissionValidation(permissions, 'simulate_bets.bets') && (
            <SimulateCoupons
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
        </MenuList>
        <MenuList disablePadding={true}>
          {permissionValidation(permissions, [
            'simulate_reports.general_stats.get',
          ]) && (
            <SimulateReports
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
        </MenuList>
      </Collapse>
    </MenuList>
  ) : null
}

export default SimulateSubMenu
