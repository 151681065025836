/* eslint-disable import/prefer-default-export */
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const Configurations: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="recommendations"
        component={ButtonLink}
        href={`/recommendations-engine/configurations`}
        selected={pathname === '/recommendations-engine/configurations'}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Recc-engine: Configurations)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.recommendations.engine.configurations',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
