import React from 'react'

const SimulateSidebarIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        id="simulate1"
        d="M12.0106 1L11.8449 1.09333V1.09567L8.11391 7.468L12.7386 15.364L11.9219 16.757L11.9301 16.771H11.9137L11.9219 16.757L7.29725 8.861L2.66675 16.771H5.73508L7.26925 14.1507L7.29725 14.1017L7.32642 14.1507L11.9219 22L14.2716 17.9843V17.9832L15.8069 15.3628L11.1846 7.46683L12.0012 6.07383L16.6236 13.9698L16.7309 14.1507L18.2639 16.771H21.3334L12.1412 1.0735L12.0106 1Z"
        fill="#F6B52D"
        className={props?.customClass}
      />
    </svg>
  )
}
export default SimulateSidebarIcon
