import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours, useAppContext } from '@novafuturltd/core'
import { FC } from 'react'
import { useOAuth } from '@novafuturltd/core'
import ComponentLibrarySubMenu from '../../modules/component-library/sidebar'
import HrBotBoMenu from '../../modules/hr-bot/components/sidebar'
import ReleaseFeature from '../../modules/release-check/components/sidebar'
import { ResponsibleGamblingSubMenu } from '../../modules/responsible-gambling/side-bar'
import SDKDebuggerSubMenu from '../../modules/sdk-debugger/sidebar'
import ToggleFeature from '../../modules/toggle-features/components/sidebar'
import AdminSubMenu from './administration'
import CampaignSubMenu from './campaign'
import CustomersSubMenu from './customers'
import DashboardMenu from './dashboard'
import ExperimentSubMenu from './experiments/index'
import OrganisationSubMenu from './organisation-settings'
import RecommendationsEngineSubMenu from './recommendations-engine'
import ReportsSubMenu from './reports'
import ServiceReportSubMenu from './service-report'
import SimulateSubMenu from './simulate/index'
import TradingSubMenu from './trading/index'
import { FeatureToggle } from '../../contexts/toggle-feature'
import { CasinoSubMenu } from '../../modules/casino/sidebar'
import { MicrofrontendRemoteExampleSidebarSubMenu } from '../../modules/microfrontend-remote-example'
import { GameAssetsSidebarSubMenu } from '../../modules/game-assets'
import AutomatedCampaignsSubMenu from './automated-campaign'
import { ACTIVATION_FEATURE } from '../../constants/toggleNames'
import { FraudDetectionSubMenu } from '../../modules/fraud-detection/side-bar'

export type TtoggleDrawerOnMobile = (props: any) => void

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1.5),
      paddingTop: theme.spacing(2),
      color: NovaColours.N040[theme.palette.mode],
    },
  }),
)

const AppMenuList: FC<object> = () => {
  const { permissions } = useOAuth()
  const classes = useStyles({})
  const {
    openDrawer,
    toggleOpenDrawer,
    hoverDrawer,
    toggleHoverDrawer,
    width,
  } = useAppContext()

  const toggleDrawerOnMobile = (event: any) => {
    event.preventDefault()
    if (width < 600) {
      toggleHoverDrawer(!hoverDrawer)
      window.scrollTo(0, 0)
      event.preventDefault()
    }
  }

  return (
    <div className={classes.root}>
      <DashboardMenu
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
      />
      <FeatureToggle.On name="feat:PDS-2367">
        <ResponsibleGamblingSubMenu
          permissions={permissions}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          hoverDrawer={hoverDrawer}
          openDrawer={openDrawer}
        />
      </FeatureToggle.On>
      <FeatureToggle.On name="feat:HUB-28">
        <FraudDetectionSubMenu
          permissions={permissions}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          hoverDrawer={hoverDrawer}
          openDrawer={openDrawer}
        />
      </FeatureToggle.On>
      <FeatureToggle.On name="epic:PDS-2431">
        <CasinoSubMenu
          permissions={permissions}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          hoverDrawer={hoverDrawer}
          openDrawer={openDrawer}
        />
      </FeatureToggle.On>
      <FeatureToggle.Off name="trading:v1-hide">
        <TradingSubMenu
          permissions={permissions}
          openDrawer={openDrawer}
          hoverDrawer={hoverDrawer}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          version={1}
        />
      </FeatureToggle.Off>
      <TradingSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        version={2}
      />
      <SimulateSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <CampaignSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <FeatureToggle.On name={ACTIVATION_FEATURE}>
        <AutomatedCampaignsSubMenu
          permissions={permissions}
          openDrawer={openDrawer}
          hoverDrawer={hoverDrawer}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
        />
      </FeatureToggle.On>

      <CustomersSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <ExperimentSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <RecommendationsEngineSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <ReportsSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
      />
      <HrBotBoMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <ToggleFeature
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <ReleaseFeature
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        toggleOpen={toggleOpenDrawer}
      />
      <AdminSubMenu
        permissions={permissions}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
      />
      <OrganisationSubMenu
        permissions={permissions}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
        toggleDrawerOnMobile={toggleDrawerOnMobile}
      />
      <FeatureToggle.On name="feat:PDS-1854">
        <ServiceReportSubMenu
          permissions={permissions}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          openDrawer={openDrawer}
          hoverDrawer={hoverDrawer}
        />
      </FeatureToggle.On>
      <ComponentLibrarySubMenu
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
      />
      <MicrofrontendRemoteExampleSidebarSubMenu
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
      />
      <FeatureToggle.On name="game-assets:games">
        <GameAssetsSidebarSubMenu
          permissions={permissions}
          toggleDrawerOnMobile={toggleDrawerOnMobile}
          openDrawer={openDrawer}
          hoverDrawer={hoverDrawer}
        />
      </FeatureToggle.On>
      <SDKDebuggerSubMenu
        toggleDrawerOnMobile={toggleDrawerOnMobile}
        openDrawer={openDrawer}
        hoverDrawer={hoverDrawer}
      />
    </div>
  )
}

export default AppMenuList
