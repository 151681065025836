import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export type MenuStyleProps = {
  openDrawer?: boolean
  hoverDrawer?: boolean
}
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: theme.spacing(45),
      color: NovaColours.N040[theme.palette.mode],
      padding: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      borderRadius: '0px 40px 40px 0px',
    },
    nestedClosed: {
      paddingLeft: theme.spacing(2.1),
    },
    menuItem: {
      height: theme.spacing(5),
      minHeight: theme.spacing(5),
      width: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer
          ? theme.spacing(30)
          : theme.spacing(5),
      maxWidth: theme.spacing(30),
      minWidth: theme.spacing(5),
      textAlign: 'left',
      marginLeft: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer
          ? 0
          : window.innerWidth > 670
          ? theme.spacing(1.2)
          : theme.spacing(0.6),
      borderRadius: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer
          ? '0px 40px 40px 0px'
          : theme.spacing(5),
      paddingLeft: (props: MenuStyleProps) =>
        !props.openDrawer && !props.hoverDrawer
          ? theme.spacing(1)
          : theme.spacing(2.2),
      transition:
        'width 0.4s, margin 0.4s, border-radius 0.8s, padding-left 0s, margin-left 0s',
    },
    expandIcon: {
      marginLeft: 'auto',
      visibility: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer ? 'visible' : 'hidden',
      opacity: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer ? '100%' : '0%',
      transition: (props: MenuStyleProps) =>
        props.openDrawer || props.hoverDrawer
          ? theme.transitions.create('opacity', {
              easing: theme.transitions.easing.easeIn,
              duration: '0.7s',
            })
          : 'none',
    },
    icon: { fill: NovaColours.N040[theme.palette.mode] },
  }),
)
