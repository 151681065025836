import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'

interface Props {
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const DashboardMenu: FC<Props> = ({
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const intl = useIntl()
  const { pathname } = useRouter()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const handleClick = () => {
    Router.push('/')
  }

  return (
    <MenuList onClick={toggleDrawerOnMobile} className={classes.root}>
      <MenuItem
        key="dashboards"
        data-test="dashboards_sidebar_menu_item"
        onClick={handleClick}
        selected={'/' === pathname}
        className={classes.menuItem}
      >
        <ListItemIcon data-test="dashboards_sidebar_icon">
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.dashboards' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  )
}

export default DashboardMenu
