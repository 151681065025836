import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
import { useIntl } from 'react-intl'
import { BetReceiptIcon } from '@novafuturltd/shared'
import { getDateXDaysAgo } from '@novafuturltd/shared/helpers/getDateXDaysAgo'
import dayjs from 'dayjs'

interface MenuBetListProps {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuBetList = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}: MenuBetListProps) => {
  const { formatMessage } = useIntl()

  const minPlacementDate = dayjs(getDateXDaysAgo(7)).format('YYYY-MM-DD')
  const maxPlacementDate = dayjs(getDateXDaysAgo(0)).format('YYYY-MM-DD')

  const placementMinMaxParam = `filter[min_placed]=${minPlacementDate}&filter[max_placed]=${maxPlacementDate}`
  return (
    <div onClick={toggleDrawerOnMobile}>
      <MenuItem
        className={nested}
        component={ButtonLink}
        href={`/casino/bet-list?page=1&page_size=10&${placementMinMaxParam}`}
        selected={pathname.substring(0, 30) === '/casino/bet-list'}
      >
        <ListItemIcon>
          <BetReceiptIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'casino.submenu.bet_list.title',
          })}
        />
      </MenuItem>
    </div>
  )
}
