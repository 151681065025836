/* eslint-disable react/no-danger */
import { NextComponentType } from 'next'
import React from 'react'

export default (ComposedComponent: NextComponentType) => (props: any) => {
  return (
    <>
      {/* @ts-ignore */}
      <ComposedComponent {...props} />
    </>
  )
}
