import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuFeatures: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="features"
        data-test="toggle-features_sidebar_menuitem"
        component={ButtonLink}
        href="/toggle-feature/features?page=1"
        selected={pathname.includes('toggle-feature/features')}
        className={nested}
      >
        <ListItemIcon data-test="feature_toggle_sidemenu_icon">
          <FeaturedPlayListIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'features.sidebar',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
