import React from 'react'
import FourZeroFour from '../../containers/Page/404'
import { useOAuth } from '@novafuturltd/core'
import { permissionValidation } from '@novafuturltd/shared'

export default (ComposedComponent: any) => (props: any) => {
  const { permissions } = useOAuth()
  const Page = ComposedComponent(props)
  if (
    Page.props['data-perform'] &&
    !permissionValidation(permissions, Page.props['data-perform'])
  ) {
    return <FourZeroFour />
  }

  return Page
}
