import {
  Button,
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
// @todo external
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
// @todo external
import { MenuApprovals } from './approvals'
import { MenuBusinessApprovals } from './business-approvals'
import { MenuEmployees } from './employees'
import { MenuExpenses } from './expenses'
import { useStyles } from '../../../../containers/Sidebar/Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  toggleOpen: (open: boolean) => void
}

const HrBotBoSubMenu: FC<Props> = ({
  permissions,
  openDrawer,
  hoverDrawer,
  toggleDrawerOnMobile,
  toggleOpen,
}) => {
  const intl = useIntl()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const { pathname } = useRouter()
  const [open, setOpen] = useState(pathname.includes('hr-bot/'))
  const handleClick = () => {
    setOpen(!open)
  }

  return permissionValidation(permissions, 'hr_bot_bo') ? (
    <MenuList aria-labelledby="nested-list-subheader" className={classes.root}>
      <MenuItem
        key="hr_bot"
        data-test="hr_bot_sidebar_submenu"
        component={Button}
        onClick={handleClick}
        className={classes.menuItem}
        selected={!openDrawer && !hoverDrawer && pathname.includes('hr-bot/')}
      >
        <ListItemIcon data-test="hr_bot_sidemenu_icon">
          <AccountCircle
            style={{ fill: '#F59C0D', width: '24px', height: '24px' }}
          />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar' })}
          hidden={!openDrawer && !hoverDrawer}
        />
        {open ? (
          <ExpandLess
            onClick={() => toggleOpen(true)}
            className={classes.expandIcon}
          />
        ) : (
          <ExpandMore
            onClick={() => toggleOpen(true)}
            className={classes.expandIcon}
          />
        )}
      </MenuItem>
      <Collapse in={open} timeout="auto" hidden={!openDrawer && !hoverDrawer}>
        <MenuList disablePadding={true}>
          {permissionValidation(permissions, 'hr_bot_bo.employee') && (
            <MenuEmployees
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(permissions, 'hr_bot_bo.expense') && (
            <MenuExpenses
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(permissions, 'hr_bot_bo.b_t_approval') && (
            <MenuBusinessApprovals
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(permissions, 'hr_bot_bo.approval') && (
            <MenuApprovals
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
        </MenuList>
      </Collapse>
    </MenuList>
  ) : null
}

export default HrBotBoSubMenu
