import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
// import withFiltersLink from '../../../constants/customersWithFiltersLink'
import { useMixPanel } from '../../../contexts/mixpanel'
import { TtoggleDrawerOnMobile } from '../Sidebar'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuCustomerOverviewV3: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="overview"
        data-testid="customers_sidebar_menuitem_overview-v2"
        component={ButtonLink}
        href="/customers/overviewV3"
        selected={'/customers/overviewV3' === pathname}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Customer Overview)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <SupervisedUserCircleIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.customers.overview.v3' })}
        />
      </MenuItem>
    </MenuList>
  )
}
