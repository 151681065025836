/* eslint-disable import/prefer-default-export */
import { ListItemIcon, ListItemText, MenuItem, MenuList, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { RecommendationsIcon } from '../../../components/uielements/icons/RecommendationsIcon'
import { ButtonLink } from '../../../components/wrappers/Button'
import { useMixPanel } from '../../../contexts/mixpanel';
import { TtoggleDrawerOnMobile } from '../Sidebar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: { fill: NovaColours.N040[theme.palette.mode] },
  }),
)

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const Recommendations: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const classes = useStyles({})
  const intl = useIntl()
  const mxp = useMixPanel()

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="recommendations"
        component={ButtonLink}
        href={`/recommendations-engine/recommendations`}
        selected={pathname.includes('/recommendations-engine/recommendations')}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Recc-engine: Recommendations)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <RecommendationsIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.recommendations.engine.recommendations',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
