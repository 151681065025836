import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useStyles } from '../Sidebar.styles'
// import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { permissionValidation } from '@novafuturltd/shared'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  version: 1 | 2
}

const TradingSubMenu: FC<Props> = ({
  permissions,
  openDrawer,
  hoverDrawer,
  toggleDrawerOnMobile,
  version,
}) => {
  const intl = useIntl()
  const { pathname } = useRouter()
  const mxp = useMixPanel()
  const basepath = pathname.split('/')[1]
  const classes = useStyles({ openDrawer, hoverDrawer })

  const handleClick = () => {
    const url =
      version === Number(2)
        ? '/trading2?sorting[0][0]=total_stake&sorting[0][1]=DESC&page_size=100&page=1&filter[sport_array][0]=Soccer'
        : '/trading?sorting[0][0]=total_stake&sorting[0][1]=DESC&page_size=100&page=1'
    Router.push(url)
    mxp.track('Sidebar Menu Item (Trading)', {
      purpose: 'track clicks of menu elements',
      version: `Trading version - ${version}`,
    })
  }

  return permissionValidation(permissions, 'vision_api.tabledata') ? (
    <MenuList onClick={toggleDrawerOnMobile} className={classes.root}>
      <MenuItem
        key="trading"
        data-test="trading_sidebar_submenu"
        selected={
          version === Number(2)
            ? basepath === 'trading2'
            : basepath === 'trading'
        }
        onClick={handleClick}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <TrendingUpIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            version === Number(2)
              ? `${intl.formatMessage({
                  id: 'sidebar.vision.trading',
                })} V2`
              : intl.formatMessage({ id: 'sidebar.vision.trading' })
          }
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default TradingSubMenu
