import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
// import AnnouncementIcon from '@mui/icons-material/Announcement'
import InboxIcon from '@mui/icons-material/Inbox'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuCommunications: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="communications"
        data-test="campaigns_sidebar_menuitem"
        component={ButtonLink}
        href="/campaign-mgmt/communications?page=1"
        selected={
          pathname.substring(0, 29) === '/campaign-mgmt/communications' &&
          pathname.substring(0, 38) !== '/campaign-mgmt/communications/calendar'
        }
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Campaigns)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon data-test="campaign_sidemenu_icon">
          <InboxIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.campaign-mgmt.campaigns',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
