import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Router, { useRouter } from 'next/router'
import React, { FC, useCallback, useMemo } from 'react'
import { TtoggleDrawerOnMobile } from '../../containers/Sidebar/Sidebar'
import { useStyles } from '../../containers/Sidebar/Sidebar.styles'

interface Props {
  permissions?: string[]
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const path = '/game-assets'

export const GameAssetsSidebarSubMenu: FC<Props> = ({
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const { pathname } = useRouter()
  const selected = useMemo(() => pathname.includes(path), [pathname])
  const onClick = useCallback(() => Router.push(path), [])
  const classes = useStyles({ openDrawer, hoverDrawer })
  return (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        selected={selected}
        onClick={onClick}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText
          primary="Game Assets"
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  )
}

export default GameAssetsSidebarSubMenu
