import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

const ReportsSubMenu: FC<Props> = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const intl = useIntl()
  const { pathname } = useRouter()
  const mxp = useMixPanel()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const handleClick = () => {
    Router.push('/reports')
    mxp.track('Sidebar Menu Item (Reports)', {
      purpose: 'track clicks of menu elements',
    })
  }

  return permissionValidation(permissions, ['looker_reports.dynamic']) ? (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        key="reports"
        data-test="reports_sidebar_submenu"
        selected={pathname.substring(0, 8) === '/reports'}
        onClick={handleClick}
        className={classes.menuItem}
      >
        <ListItemIcon data-test="reports_sidemenu_icon">
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.reports' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default ReportsSubMenu
