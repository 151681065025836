import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { TtoggleDrawerOnMobile } from '../../containers/Sidebar/Sidebar'
import useOrgChecker from '../../hooks/useOrgChecker'
import { useStyles } from '../../containers/Sidebar/Sidebar.styles'

interface Props {
  permissions?: []
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const ComponentLibrarySubMenu: FC<Props> = ({
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const { pathname } = useRouter()
  const { isNova } = useOrgChecker()
  const classes = useStyles({ openDrawer, hoverDrawer })

  return isNova ? (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        key="reports"
        data-test="reports_sidebar_submenu"
        selected={pathname.includes('/component-library')}
        onClick={() => Router.push('/component-library')}
        className={classes.menuItem}
      >
        <ListItemIcon data-test="reports_sidemenu_icon">
          <PhotoLibraryIcon />
        </ListItemIcon>
        <ListItemText primary="Component Library" hidden={!openDrawer && !hoverDrawer} />
      </MenuItem>
    </MenuList>
  ) : null
}

export default ComponentLibrarySubMenu
