import AccountCircle from '@mui/icons-material/AccountCircle'
import MoreIcon from '@mui/icons-material/MoreVert'
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Menu,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import { useAppContext, useOAuth } from '@novafuturltd/core'
import {
  NovaTextLogoIcon,
  SidebarToggle,
  ThemeModeSwitcher,
} from '@novafuturltd/shared'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import CurrencyPreferenceSelect from '../../components/CurrencyPreferenceSelect'
import { HelpButton } from '../../components/help/button'
import { ButtonLink } from '../../components/wrappers/Button'
import { useMixPanel } from '../../contexts/mixpanel'
import { FeatureToggle } from '../../contexts/toggle-feature'
import { OrganisationSwitcher } from './OrganisationSwitcher'
import { useStyles } from './TopbarStyle.styles'
import TopbarUser from './topbarUser'

const HeaderComponents: FC<{}> = ({}) => {
  const {
    view,
    openDrawer,
    hoverDrawer,
    mode,
    // tslint:disable-next-line: no-shadowed-variable
    toggleHoverDrawer,
    // tslint:disable-next-line: no-shadowed-variable
    toggleOpenDrawer,
    toggleDarkMode,
  } = useAppContext()
  const { organizationId, profile } = useOAuth()
  const router = useRouter()
  const isOnTradingPage = router.pathname.includes('/trading')
  const mxp = useMixPanel()
  const classes = useStyles({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const toggleDrawer = (event: any) => {
    event.preventDefault()
    if (view === 'MobileView') {
      toggleHoverDrawer(!hoverDrawer)
    } else {
      toggleOpenDrawer(!openDrawer)
    }
    window.scrollTo(0, 0)
    mxp.track('Sidebar drawer toggle ', {
      purpose: 'Track Menu is open or closed',
    })
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Popper
      open={isMenuOpen}
      anchorEl={anchorEl}
      role={undefined}
      placement="bottom-end"
      transition
      style={{ zIndex: 100000 }}
      className={classes.menuItemContainer}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleMenuClose}>
              <MenuList
                id="composition-menu"
                aria-labelledby="composition-button"
                className={classes.menuItemContainer}
                style={{ padding: '0px', margin: '0px' }}
              >
                <TopbarUser className={classes.menuItem} />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted={true}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <TopbarUser />
    </Menu>
  )

  const showOrganizationSwitcher = () => {
    return (
      profile &&
      profile.organizations &&
      profile.organizations.length > 1 && (
        <OrganisationSwitcher
          organisations={profile.organizations as []}
          current={organizationId || ''}
        />
      )
    )
  }

  const showNovaLogo = () => {
    if (view === 'MobileView') {
      return <></>
    }
    return (
      <Button component={ButtonLink} href={'/'}>
        <NovaTextLogoIcon />
      </Button>
    )
  }

  const handleModeToggle = () => {
    toggleDarkMode()
  }

  return (
    <>
      <SidebarToggle
        state={hoverDrawer}
        onToggleHandle={toggleDrawer}
        view={view}
      />

      {showNovaLogo()}

      {showOrganizationSwitcher()}

      <div className={classes.grow} />
      <FeatureToggle name="feat:PDS-2261">
        {isOnTradingPage && <CurrencyPreferenceSelect />}
      </FeatureToggle>
      <ThemeModeSwitcher
        data-test="theme-switcher"
        isDark={mode === 'dark'}
        onChangeThemeMode={handleModeToggle}
      />
      <div className={classes.sectionDesktop}>
        <HelpButton />
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          size="large"
        >
          <AccountCircle
            style={{ fill: '#F59C0D', width: '36px', height: '36px' }}
          />
        </IconButton>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
          size="large"
        >
          <MoreIcon />
        </IconButton>
      </div>
      {renderMobileMenu}
      {renderMenu}
    </>
  )
}

export default HeaderComponents
