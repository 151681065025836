import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'
import { Router, To } from 'react-router-dom'

const useReactRouterAdapter = () => {
  const router = useRouter()
  const { pathname, query } = router
  const search = Object.keys(query).reduce((acc, key) => {
    const value = query[key]
    if (Array.isArray(value)) {
      return `${acc}${key}=${value.join(',')}&`
    }
    return `${acc}${key}=${value}&`
  }, '?')
  const { slug } = query
  return {
    location: {
      pathname: pathname.replace(
        '[[...slug]]',
        slug ? (typeof slug === 'string' ? slug : slug.join('/')) : '',
      ),
      search,
    },
    navigator: {
      createHref: (to: string) => to,
      go: (to: number) => {
        if (typeof window !== 'undefined') {
          window.history.go(to)
        }
      },
      push: (to: To, state?: any) => {
        router.push(to, state)
      },
      replace: (to: To, state?: any) => {
        router.replace(to, state)
      },
    },
  }
}

export const ReactRouterAdapter: FC<PropsWithChildren<{ basename: string }>> = ({
  basename,
  children,
}) => {
  const router = useReactRouterAdapter()
  return (
    <Router basename={basename} {...router}>
      {children}
    </Router>
  )
}

export default ReactRouterAdapter
