import { SvgIcon, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: NovaColours.N030[theme.palette.mode],
    },
  }),
)

export const MadeWithLove: FC = () => {
  const intl = useIntl()
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <span>{intl.formatMessage({ id: 'app.footer.text' })}</span>
      <SvgIcon>
        <path
          opacity="0.6"
          d="M10.332 10.4475L2.83203 8.17255L10.332 15.7775V10.4475Z"
          fill="#00D874"
        />
        <path
          opacity="0.8"
          d="M10.332 10.4475L17.832 8.17255L10.332 15.7775V10.4475Z"
          fill="#00D874"
        />
        <path
          opacity="0.8"
          d="M10.3327 6.29919L6.16602 4.16669L10.3327 10.4475V6.29919Z"
          fill="#00D874"
        />
        <path
          opacity="0.4"
          d="M2.83203 8.17252L10.332 10.4475L6.16536 4.16669L2.83203 8.17252Z"
          fill="#00D874"
        />
        <path
          opacity="0.4"
          d="M10.332 6.29919L14.4987 4.16669L10.332 10.4475V6.29919Z"
          fill="#00D874"
        />
        <path
          opacity="0.6"
          d="M17.832 8.17252L10.332 10.4475L14.4987 4.16669L17.832 8.17252Z"
          fill="#00D874"
        />
      </SvgIcon>
      <span>{intl.formatMessage({ id: 'app.footer.signature' })}</span>
    </div>
  )
}
