import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Router, { useRouter } from 'next/router'
import React, { FC, useCallback, useMemo } from 'react'
import { TtoggleDrawerOnMobile } from '../../containers/Sidebar/Sidebar'
import useOrgChecker from '../../hooks/useOrgChecker'
import { useStyles } from '../../containers/Sidebar/Sidebar.styles'

interface Props {
  permissions?: []
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const path = '/microfrontend-remote-example'

export const MicrofrontendRemoteExampleSidebarSubMenu: FC<Props> = ({ toggleDrawerOnMobile, openDrawer, hoverDrawer }) => {
  const { pathname } = useRouter()
  const { isNova } = useOrgChecker()
  const selected = useMemo(() => pathname.includes(path), [pathname])
  const onClick = useCallback(() => Router.push(path), [])
  const classes = useStyles({ openDrawer, hoverDrawer })
  return isNova ? (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        selected={selected}
        onClick={onClick}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="MF Remote Example" hidden={!openDrawer && !hoverDrawer} />
      </MenuItem>
    </MenuList>
  ) : null
}

export default MicrofrontendRemoteExampleSidebarSubMenu
