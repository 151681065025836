import { NextComponentType } from 'next'
import React from 'react'
import Layout from '../../containers/App/layout'

export default (ComposedComponent: NextComponentType) => (props: any) => {
  return (
    <Layout>
      <ComposedComponent {...props} />
    </Layout>
  )
}
