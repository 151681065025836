import AllInboxIcon from '@mui/icons-material/AllInbox'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import { permissionValidation } from '@novafuturltd/shared'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useMixPanel } from '../../../contexts/mixpanel'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

const AutomatedCampaignsSubMenu: FC<Props> = ({
  permissions,
  openDrawer,
  hoverDrawer,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const { pathname } = useRouter()
  const mxp = useMixPanel()
  const basepath = pathname.split('/')[1]
  const classes = useStyles({ openDrawer, hoverDrawer })

  const handleClick = () => {
    Router.push('/automated-campaigns')
    mxp.track('Sidebar Menu Item (Automated Campaigns)', {
      purpose: 'track clicks of menu elements',
    })
  }

  return permissionValidation(
    permissions,
    'market_campaign_optimiser.v1_get_filtertype.*',
  ) ? (
    <MenuList onClick={toggleDrawerOnMobile} className={classes.root}>
      <MenuItem
        key="automated-campaigns"
        data-test="automated-campaigns_sidebar_submenu"
        selected={basepath === 'automated-campaigns'}
        onClick={handleClick}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.automated-campaigns' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default AutomatedCampaignsSubMenu
