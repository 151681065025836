import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
import { useIntl } from 'react-intl'

interface MenuIdentifypProps {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuIdentify = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}: MenuIdentifypProps) => {
  const { formatMessage } = useIntl()
  return (
    <div onClick={toggleDrawerOnMobile}>
      <MenuItem
        className={nested}
        component={ButtonLink}
        href="/responsible-gambling/identify?sorting[0][0]=risk&sorting[0][1]=desc&range=monthly"
        selected={
          pathname.substring(0, 30) === '/responsible-gambling/identify'
        }
        // onClick={toggleDrawerOnMobile}
      >
        <ListItemIcon>
          <GpsFixedIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'responsible_gambling.menu.submenu.title',
          })}
        />
      </MenuItem>
    </div>
  )
}
