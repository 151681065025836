import { Box, CircularProgress } from '@mui/material'
import { NextComponentType } from 'next'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useOAuth } from '@novafuturltd/core'
import redirect from '../../helpers/redirect'

export default (ComposedComponent: NextComponentType) => (props: any) => {
  const { tokenData } = useOAuth()
  const { asPath, pathname } = useRouter()
  useEffect(() => {
    if (!tokenData) {
      redirect({}, `/signin?authOrigin=${asPath || '/'}&pathname=${pathname}`)
    }
  }, [tokenData])
  if (!tokenData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        id={'secured-page-with-data'}
      >
        <CircularProgress color="primary" size={170} thickness={1} />
      </Box>
    )
  }
  return <ComposedComponent {...props} />
}
