import {
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { permissionValidation } from '@novafuturltd/shared'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { MenuFraudIdentify } from './sub-menus/menuFraudType12'
import { TtoggleDrawerOnMobile } from '../../../containers/Sidebar/Sidebar'
import { MixpanelContext } from '../../../contexts/mixpanel'
import { useStyles } from '../../../containers/Sidebar/Sidebar.styles'
import { Radar } from '@mui/icons-material'

interface FraudDetectionSubMenuProps {
  hoverDrawer?: boolean
  openDrawer?: boolean
  permissions: string[]
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const FraudDetectionSubMenu = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}: FraudDetectionSubMenuProps) => {
  const { formatMessage } = useIntl()
  const { pathname } = useRouter()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const [open, setOpen] = useState(pathname.includes('/fraud-detection'))
  const mxp = useContext(MixpanelContext)
  const handleClick = () => {
    setOpen(!open)
    mxp.track('click', {
      element: 'fraud-detection menu click',
      section: 'sidebar',
    })
  }
  return permissionValidation(
    permissions,
    'ml_fraud_detection.v1_0_0_retrieve_batch_predictions_types_1_2.get',
  ) ? (
    <MenuList aria-labelledby="nested-list-subheader" className={classes.root}>
      <MenuItem
        onClick={handleClick}
        className={classes.menuItem}
        selected={
          !openDrawer && !hoverDrawer && pathname.startsWith('/fraud-detection')
        }
      >
        <ListItemIcon>
          <Radar />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'fraud_detection.main.title',
          })}
          hidden={!openDrawer && !hoverDrawer}
        />
        {open ? (
          <ExpandLess className={classes.expandIcon} />
        ) : (
          <ExpandMore className={classes.expandIcon} />
        )}
      </MenuItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        hidden={!openDrawer && !hoverDrawer}
      >
        <MenuList component="div" disablePadding>
          <MenuFraudIdentify
            pathname={pathname}
            toggleDrawerOnMobile={toggleDrawerOnMobile}
            nested={
              openDrawer || hoverDrawer ? classes.nested : classes.nestedClosed
            }
          />
        </MenuList>
      </Collapse>
    </MenuList>
  ) : null
}
