import { SvgIcon, SvgIconProps } from '@mui/material'
import React, { forwardRef } from 'react'

const ExperimentsIcon = forwardRef((props: SvgIconProps, ref: any) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91114 6.89402C7.50902 7.26992 6.96888 7.5 6.375 7.5C5.13236 7.5 4.125 6.49264 4.125 5.25C4.125 4.00736 5.13236 3 6.375 3C7.54048 3 8.49899 3.88614 8.61353 5.02142L10.5424 5.74473C11.5242 4.10086 13.3211 3 15.375 3C18.4816 3 21 5.5184 21 8.625C21 10.7534 19.8179 12.6057 18.0744 13.5612L19.0612 16.5213C20.1565 16.6729 21 17.6129 21 18.75C21 19.9926 19.9926 21 18.75 21C17.5074 21 16.5 19.9926 16.5 18.75C16.5 18.1262 16.7539 17.5617 17.1639 17.1541L16.1769 14.1933C15.915 14.2307 15.6473 14.25 15.375 14.25C14.1829 14.25 13.0775 13.8792 12.1677 13.2466L9.36196 16.0523C9.60974 16.522 9.75 17.0571 9.75 17.625C9.75 19.489 8.23896 21 6.375 21C4.51104 21 3 19.489 3 17.625C3 15.761 4.51104 14.25 6.375 14.25C6.94295 14.25 7.47813 14.3903 7.94778 14.6381L10.7535 11.8324C10.1208 10.9226 9.75 9.81711 9.75 8.625C9.75 8.28103 9.78087 7.94427 9.84 7.61735L7.91114 6.89402Z"
      />
    </SvgIcon>
  )
})

export default ExperimentsIcon

export const CreateExperimentIcon = forwardRef(
  (props: SvgIconProps, ref: any) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.375 7.5C6.96888 7.5 7.50902 7.26992 7.91114 6.89402L9.84 7.61735C9.78087 7.94427 9.75 8.28103 9.75 8.625C9.75 9.81711 10.1208 10.9226 10.7535 11.8324L7.94778 14.6381C7.47813 14.3903 6.94295 14.25 6.375 14.25C4.51104 14.25 3 15.761 3 17.625C3 19.489 4.51104 21 6.375 21C8.23896 21 9.75 19.489 9.75 17.625C9.75 17.0571 9.60974 16.522 9.36196 16.0523L12.1677 13.2466C13.0775 13.8792 14.1829 14.25 15.375 14.25C15.6473 14.25 15.915 14.2307 16.1769 14.1933L17.1639 17.1541C16.7539 17.5617 16.5 18.1262 16.5 18.75C16.5 19.9926 17.5074 21 18.75 21C19.9926 21 21 19.9926 21 18.75C21 17.6129 20.1565 16.6729 19.0612 16.5213L18.0744 13.5612C19.8179 12.6057 21 10.7534 21 8.625C21 5.5184 18.4816 3 15.375 3C13.3211 3 11.5242 4.10086 10.5424 5.74473L8.61353 5.02142C8.49899 3.88614 7.54048 3 6.375 3C5.13236 3 4.125 4.00736 4.125 5.25C4.125 6.49264 5.13236 7.5 6.375 7.5ZM16.1055 5.15753V7.89452H18.8425V9.26301H16.1055V12H14.737V9.26301H12V7.89452H14.737V5.15753H16.1055Z"
        />
      </SvgIcon>
    )
  },
)
