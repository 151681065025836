import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { webManagerURI } from '../../../config/index'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'

interface Props {
  permissions: string[]
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const AdminSubMenu: FC<Props> = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const intl = useIntl()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  return permissionValidation(permissions, [
    'nova_auth.application_management.list',
  ]) ? (
    <MenuList onClick={toggleDrawerOnMobile} className={classes.root}>
      <MenuItem
        key="administration"
        data-test="administration_sidebar_submenu"
        onClick={() => {
          openInNewTab(webManagerURI)
        }}
        className={classes.menuItem}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.administration' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default AdminSubMenu
