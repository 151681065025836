import { useOAuth } from '@novafuturltd/core'
import dynamic from 'next/dynamic'
import ErrorBoundary from '../../components/ErrorBoundary'
import ReactRouterAdapter from '../../components/ReactRouterAdapter'

const RemoteApp = dynamic(
  () => {
    // @ts-ignore
    return import('game_assets/App')
  },
  {
    ssr: false,
  },
)

export const GameAssets = () => {
  const { tokenData, permissions } = useOAuth()
  return (
    <ReactRouterAdapter basename={'game-assets'}>
      <ErrorBoundary>
        {/* @ts-ignore */}
        <RemoteApp tokenData={tokenData} permission={permissions} />
      </ErrorBoundary>
    </ReactRouterAdapter>
  )
}

export default GameAssets
