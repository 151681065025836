/* eslint-disable import/prefer-default-export */
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt'

import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const SimulateCoupons: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="nigeria"
        data-test="simulate_sidebar_menuitem_coupons"
        component={ButtonLink}
        href="/simulate/coupons?page=0"
        selected={'/simulate/coupons' === pathname}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Coupons)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.simulate.coupons' })}
        />
      </MenuItem>
    </MenuList>
  )
}
