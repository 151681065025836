import { useMemo } from "react";
import { useOAuth } from "@novafuturltd/core"

const useOrgChecker = () => {
    const { organizationId: orgId } = useOAuth();
    const isNova = useMemo(() => ['cd151834-fb70-4b7d-81e1-a034dfb037b7'].includes(orgId || ''), [orgId]);
    return { isNova }
}

export default useOrgChecker;
