import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
  } from '@mui/material'
  import { FC } from 'react'
  import { useIntl } from 'react-intl'
  import { ButtonLink } from '../../../../components/wrappers/Button'
  import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
  import { useMixPanel } from '../../../../contexts/mixpanel'
import { DesktopTemplateIcon } from '@novafuturltd/shared/icons/desktopTemplateIcon'
import { NovaColours, useAppContext } from '@novafuturltd/core'
  
  interface Props {
    pathname: string
    nested?: string
    toggleDrawerOnMobile: TtoggleDrawerOnMobile
  }
  
  export const MenuLandingPages: FC<Props> = ({
    pathname,
    nested,
    toggleDrawerOnMobile,
  }) => {
    const intl = useIntl()
    const mxp = useMixPanel()
    const { mode } = useAppContext();
   const defaultFill = NovaColours.N040[mode]
    return (
       <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
        <MenuItem
          key="landingPages"
          data-test="landing_pages_sidebar_menuitem"
          component={ButtonLink}
          href="/campaign-mgmt/landing-pages"
          selected={pathname.substring(0, 24) === '/campaign-mgmt/landing-pages'}
          className={nested}
          onClick={() => {
            mxp.track('Sidebar Menu Item (Campaign-mgmt: Landing Pages)', {
              purpose: 'track clicks of menu elements',
            })
          }}
        >
          <ListItemIcon data-test="campaign_landing_page_sidemenu_icon">
           <DesktopTemplateIcon fill={defaultFill}/>
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              id: 'sidebar.campaign-mgmt.landingpages',
            })}
          />
        </MenuItem>
      </MenuList>
    )
  }
  