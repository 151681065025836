import dynamic from 'next/dynamic'
import ErrorBoundary from '../../components/ErrorBoundary'
import ReactRouterAdapter from '../../components/ReactRouterAdapter'

const RemoteApp = dynamic(
  () => {
    // @ts-ignore
    return import('microfrontend_remote_example/App')
  },
  {
    ssr: false,
  },
)

export const MicrofrontendRemoteExample = () => {
  return (
    <ReactRouterAdapter basename={'microfrontend-remote-example'}>
      <ErrorBoundary>
        <RemoteApp />
      </ErrorBoundary>
    </ReactRouterAdapter>
  )
}

export default MicrofrontendRemoteExample
