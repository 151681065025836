import HistoryIcon from '@mui/icons-material/History'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuFeatures: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="releases"
        data-test="check-releases_sidebar_menuitem"
        component={ButtonLink}
        href="/check-release/releases"
        selected={pathname.includes('check-release/releases')}
        className={nested}
      >
        <ListItemIcon data-test="check-releases_sidemenu_icon">
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'release-check.sidebar',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
