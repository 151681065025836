import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import { FolderDeleteIcon } from '@novafuturltd/shared'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { useMixPanel } from '../../../contexts/mixpanel'
import { TtoggleDrawerOnMobile } from '../Sidebar'
// import FolderDeleteIcon from '@mui/icons-material/FolderDelete'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuDeletedItems: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="communications"
        data-test="campaigns_sidebar_menuitem"
        component={ButtonLink}
        href="/campaign-mgmt/deleted"
        selected={
          pathname.substring(0, 38) === '/campaign-mgmt/communications/deleted'
        }
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Campaign-mgmt: Deleted Items)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon data-test="campaign_sidemenu_icon">
          <FolderDeleteIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'template.deleted.items.title',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
