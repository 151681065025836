import {
  Avatar,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import InputBase from '@mui/material/InputBase'
import { toFirstLetters } from '../../helpers/StringHelper'

interface Props {
  organisations: []
  current: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(2),
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '1px',
        height: '100%',
        background: NovaColours.N070[theme.palette.mode],
        bottom: 0,
        left: theme.spacing(-2),
      },
    },
    input: {
      color: NovaColours.N030[theme.palette.mode],
      display: 'flex',
      alignItems: 'center',
      borderRadius: 6,
      position: 'relative',
      backgroundColor: NovaColours.N100[theme.palette.mode],
      border: `solid 1px ${NovaColours.N070[theme.palette.mode]}`,
      fontSize: 16,
      padding: theme.spacing(1, 3, 1, 1.8),
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 6,
        borderColor: '#e9e9e9',
        boxShadow: '0 0 0 0.2rem rgba(#e9e9e9,.25)',
      },
    },
    popover: {
      '& .MuiBackdrop-root': {
        backgroundColor: `${NovaColours.S090[theme.palette.mode]}3C`,
        backdropFilter: 'blur(4px)',
      },
      '& .MuiPaper-root': {
        border: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
      },
      '& .MuiList-root': {
        alignItems: 'center',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        boxShadow: theme.shadows[5],
      },
    },
    menuItem: {
      fontSize: theme.typography.h3.fontSize,
      fontColor: NovaColours.N020[theme.palette.mode],
      fontWeight: 400,
      alignItems: 'center',
      borderBottom: `1px solid ${NovaColours.N070[theme.palette.mode]}`,
      '&:first-child': {
        borderTop: 'none',
        '&:hover': {
          borderRadius: theme.spacing(0.625, 0.625, 0, 0),
        },
      },
      '&:last-child': {
        borderBottom: 'none',
        borderRadiusTop: theme.spacing(0.625),
        borderBottomLeftRadius: theme.spacing(0.625),
        borderBottomRightRadius: theme.spacing(0.625),
        '&:hover': {
          borderRadius: theme.spacing(0, 0, 0.625, 0.625),
        },
      },
    },
    icon: {
      width: '30px',
      height: '30px',
      fontSize: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  }),
)

export const OrganisationSwitcher: FC<Props> = ({ organisations, current }) => {
  const router = useRouter()
  const classes = useStyles({})
  const handleChange = (
    e: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    router.push(
      `/switch-org?id=${e.target.value}&authOrigin=${router.asPath}&pathname=${router.pathname}`,
    )
  }
  return (
    <Select
      variant="outlined"
      value={current as any} /** TODO @goughjo03 check this type */
      onChange={handleChange}
      MenuProps={{
        PopoverClasses: { root: classes.popover },
      }}
      input={
        <InputBase
          className={classes.root}
          classes={{ input: classes.input }}
        />
      }
    >
      {organisations.map((org: { name: string; idOrganization: string }) => (
        <MenuItem
          className={classes.menuItem}
          value={org.idOrganization}
          key={org.idOrganization}
        >
          <Avatar className={classes.icon}>{toFirstLetters(org.name)}</Avatar>
          <span>{org.name}</span>
        </MenuItem>
      ))}
    </Select>
  )
}
