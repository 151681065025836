import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonLink } from '../../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../../../../containers/Sidebar/Sidebar'
import { useIntl } from 'react-intl'

interface MenuFraudDetectionProps {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuFraudIdentify = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}: MenuFraudDetectionProps) => {
  const { formatMessage } = useIntl()
  return (
    <div onClick={toggleDrawerOnMobile}>
      <MenuItem
        className={nested}
        component={ButtonLink}
        href="/fraud-detection/types?page=1&page_size=10&sorting[0][0]=date_flagged&sorting[0][1]=desc"
        selected={pathname.substring(0, 30) === '/fraud-detection/types'}
      >
        <ListItemIcon>
          <GpsFixedIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'fraud_detection.menu.submenu.title',
          })}
        />
      </MenuItem>
    </div>
  )
}
