import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { CalViewIcon } from '../../../components/uielements/icons/CalViewIcon'
import { ButtonLink } from '../../../components/wrappers/Button'
import { useMixPanel } from '../../../contexts/mixpanel'
import { TtoggleDrawerOnMobile } from '../Sidebar'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const MenuCalendar: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="communications"
        data-test="campaigns_sidebar_menuitem"
        component={ButtonLink}
        href="/campaign-mgmt/communications/calendar"
        selected={
          pathname.substring(0, 38) === '/campaign-mgmt/communications/calendar'
        }
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Campaign-mgmt: Calendar)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon data-test="campaign_sidemenu_icon">
          <CalViewIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({
            id: 'sidebar.campaign-mgmt.calendar',
          })}
        />
      </MenuItem>
    </MenuList>
  )
}
