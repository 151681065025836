/* eslint-disable import/prefer-default-export */
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import AssessmentIcon from '@mui/icons-material/Assessment'

import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../components/wrappers/Button'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useMixPanel } from '../../../contexts/mixpanel'

interface Props {
  pathname: string
  nested?: string
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const SimulateReports: FC<Props> = ({
  pathname,
  nested,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  const [dateToday] = useState(new Date().toISOString().substr(0, 10))

  return (
    <MenuList disablePadding={true} onClick={toggleDrawerOnMobile}>
      <MenuItem
        key="nigeria"
        data-test="simulate_sidebar_menuitem_reports"
        component={ButtonLink}
        href={`/simulate/reports?filter[start_date]=${dateToday}&filter[end_date]=${dateToday}`}
        selected={pathname.includes('/simulate/reports')}
        className={nested}
        onClick={() => {
          mxp.track('Sidebar Menu Item (Reports)', {
            purpose: 'track clicks of menu elements',
          })
        }}
      >
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.simulate.reports' })}
        />
      </MenuItem>
    </MenuList>
  )
}
