import {
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { permissionValidation } from '@novafuturltd/shared'
import { TrafficIcon } from '@novafuturltd/shared'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { MenuIdentify } from './sub-menus/menuIdentify'
import { TtoggleDrawerOnMobile } from '../../../containers/Sidebar/Sidebar'
import { MixpanelContext } from '../../../contexts/mixpanel'
import { useStyles } from '../../../containers/Sidebar/Sidebar.styles'
import { MenuClusterInfo } from './sub-menus/menuClusterInfo'
import { FeatureToggle } from '../../../contexts/toggle-feature'

interface ResponsibleGamblingSubMenuProps {
  hoverDrawer?: boolean
  openDrawer?: boolean
  permissions: string[]
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

export const ResponsibleGamblingSubMenu = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}: ResponsibleGamblingSubMenuProps) => {
  const { formatMessage } = useIntl()
  const { pathname } = useRouter()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const [open, setOpen] = useState(pathname.includes('/responsible-gambling'))
  const mxp = useContext(MixpanelContext)
  const handleClick = () => {
    setOpen(!open)
    mxp.track('click', {
      element: 'responsible-gambling menu click',
      section: 'sidebar',
    })
  }
  return permissionValidation(permissions, 'responsible_gambling.*.*') ? (
    <MenuList aria-labelledby="nested-list-subheader" className={classes.root}>
      <MenuItem
        onClick={handleClick}
        className={classes.menuItem}
        selected={
          !openDrawer &&
          !hoverDrawer &&
          pathname.startsWith('/responsible-gambling')
        }
      >
        <ListItemIcon>
          <TrafficIcon />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({
            id: 'responsponsible_gambling.menu.title',
          })}
          hidden={!openDrawer && !hoverDrawer}
        />
        {open ? (
          <ExpandLess className={classes.expandIcon} />
        ) : (
          <ExpandMore className={classes.expandIcon} />
        )}
      </MenuItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        hidden={!openDrawer && !hoverDrawer}
      >
        <MenuList component="div" disablePadding>
          <MenuIdentify
            pathname={pathname}
            toggleDrawerOnMobile={toggleDrawerOnMobile}
            nested={
              openDrawer || hoverDrawer ? classes.nested : classes.nestedClosed
            }
          />
        </MenuList>
        <FeatureToggle.On name="responsible-gambling:cluster-info">
          <MenuList component="div" disablePadding>
            <MenuClusterInfo
              pathname={pathname}
              toggleDrawerOnMobile={toggleDrawerOnMobile}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
            />
          </MenuList>
        </FeatureToggle.On>
      </Collapse>
    </MenuList>
  ) : null
}
