import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NovaColours } from '@novafuturltd/core'
import React from 'react'

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      fill: NovaColours.N040[theme.palette.mode],
    },
  }),
)

export const CalViewIcon = ({ ...props }: any) => {
  const classes = useStyles({})
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H20C21.1 3 22 3.9 22 5V21C22 22.1 21.1 23 20 23H4C2.9 23 2 22.1 2 21V5C2 3.9 2.9 3 4 3H5V1H7V3H17V1H19V3ZM4 21H20V8H4V21ZM10.5176 18.3242H13.5293V15.3125H10.5176V18.3242ZM9.01177 18.3242H6V15.3125H9.01177V18.3242ZM6 13.8065H9.01177V10.7948H6V13.8065ZM13.5293 13.8065H10.5176V10.7948H13.5293V13.8065ZM15.0354 13.8065H18.0472V10.7948H15.0354V13.8065ZM18.0472 18.3242H15.0354V15.3125H18.0472V18.3242Z"
        className={classes.icon}
        {...props}
      />
    </svg>
  )
}
