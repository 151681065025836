import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'
import { useMixPanel } from '../../../contexts/mixpanel'
import { permissionValidation } from '@novafuturltd/shared'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
}

const OrgSettingsSubMenu: FC<Props> = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const intl = useIntl()
  const mxp = useMixPanel()
  const { pathname } = useRouter()
  const classes = useStyles({ openDrawer, hoverDrawer })

  return permissionValidation(permissions, [
    'userpref_service.org_settings.get_modules',
  ]) ? (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        key="reports"
        data-test="reports_sidebar_submenu"
        onClick={() => {
          Router.push('/organisation-settings')
          mxp.track('Sidebar Menu Item (Org. Settings)', {
            purpose: 'track clicks of menu elements',
          })
        }}
        selected={pathname.startsWith('/organisation-settings')}
        className={classes.menuItem}
      >
        <ListItemIcon data-test="reports_sidemenu_icon">
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.vision.org.settings' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default OrgSettingsSubMenu
