import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import Router, { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useOAuth } from '@novafuturltd/core'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { useStyles } from '../Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'

interface Props {
  permissions: string[]
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  openDrawer: boolean
  hoverDrawer: boolean
}

const ServiceReportSubMenu: FC<Props> = ({
  permissions,
  toggleDrawerOnMobile,
  openDrawer,
  hoverDrawer,
}) => {
  const intl = useIntl()
  const { pathname } = useRouter()
  const { organizationId: orgId } = useOAuth()
  const classes = useStyles({ openDrawer, hoverDrawer })

  return ['cd151834-fb70-4b7d-81e1-a034dfb037b7'].includes(orgId || '') &&
    permissionValidation(permissions, ['']) ? (
    <MenuList
      aria-labelledby="nested-list-subheader"
      onClick={toggleDrawerOnMobile}
      className={classes.root}
    >
      <MenuItem
        key="reports"
        data-test="reports_sidebar_submenu"
        selected={pathname.includes('/service-report')}
        onClick={() => Router.push('/service-report')}
        className={classes.menuItem}
      >
        <ListItemIcon data-test="reports_sidemenu_icon">
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.service.report' })}
          hidden={!openDrawer && !hoverDrawer}
        />
      </MenuItem>
    </MenuList>
  ) : null
}

export default ServiceReportSubMenu
